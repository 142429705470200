import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <h4>{`I love to dev on HTML / CSS / JavaScript. I'm more specialized on React as a Front Dev, but i have a good base on SQL Servers.`}</h4>
    <p>{`I will finish a better version of my PortFolio very soon !`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      