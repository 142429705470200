import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Hi, I'm Jérôme Aspar`}</h1>
    <p>{`I'm a new Web Dev FullStack on Java Script,
freshly graduated from the famous French Online Web School `}<a parentName="p" {...{
        "href": "https://oclock.io/"
      }}>{`O'clock.io`}</a>{` !`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      